import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'

import { Zone, primary, darkGrey, above } from '../elements'

const Contact = ({ data }) => {
  const postNode = {
    title: `Contact - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Contact - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <Zone modifiers={['blank', 'center', 'short']} mini>
        <div className="zone-content">
          <h2>Get in Touch</h2>
          <p>
            Give us a ring at{' '}
            <Phone href="tel:+18002237671" rel="nofollow">
              (800) 223-7671
            </Phone>
            , we love to talk.
          </p>
        </div>
        <AddressGroup>
          <div>
            <h5>Visit Headquarters</h5>
            <Address>
              1735 Main Street
              <br />
              Gooding, Idaho 83330
            </Address>
          </div>
          <div>
            <h5>Snail Mail Us</h5>
            <Address>
              PO Box 116
              <br />
              Gooding, Idaho 83330
            </Address>
          </div>
        </AddressGroup>
      </Zone>

      <Container>
        <PageTitle>Contact</PageTitle>
        <ContactForm />
      </Container>
    </Layout>
  )
}

export default Contact

const Phone = styled.a`
  color: ${darkGrey};
  text-transform: uppercase;
  text-decoration: none;
  font-weight: normal;
  transition: 0.3s ease border;
  padding: 0;
  &:hover,
  &.active {
    color: ${primary};
  }
`
const AddressGroup = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  ${above.sm`
    flex-direction: row;
  `};
  div {
    flex-grow: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  h5 {
    color: ${primary};
  }
`
const Address = styled.address`
  color: ${darkGrey};
  line-height: 1.4em;
  font-size: 1.2rem;
`
