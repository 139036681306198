import React from 'react'
import PropTypes from 'prop-types'
import Recaptcha from 'react-google-recaptcha'
import styled from 'styled-components'
import classnames from 'classnames'
import {
  mdGrey,
  ltGrey,
  darkGrey,
  FormButton,
  secondary,
  danger,
} from '../elements'

/*
  ⚠️ This is an example of a contact form powered with Netlify form handling.
  Be sure to review the Netlify documentation for more information:
  https://www.netlify.com/docs/form-handling/
*/
const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const InputFeedback = ({ error }) =>
  error ? <div className="input-feedback">{error}</div> : null

const Label = ({ className, error, children, ...props }) => {
  return (
    <label className="label" {...props}>
      {children}
    </label>
  )
}

const TextInput = ({
  label,
  error,
  id,
  name,
  type,
  placeholder,
  value,
  onChange,
  className,
  ...props
}) => {
  const classes = classnames(
    'input-group',
    {
      'animated shake error': !!error,
    },
    className
  )
  return (
    <div className={classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <input
        id={id}
        className="text-input"
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />
      <InputFeedback error={error} />
    </div>
  )
}

const TextArea = ({
  label,
  error,
  id,
  name,
  type,
  placeholder,
  value,
  onChange,
  className,
  ...props
}) => {
  const classes = classnames(
    'input-group',
    {
      'animated shake error': !!error,
    },
    className
  )
  return (
    <div className={classes}>
      <Label htmlFor={id} error={error}>
        {label}
      </Label>
      <textarea
        id={id}
        className="text-input"
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...props}
      />
      <InputFeedback error={error} />
    </div>
  )
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      showModal: false,
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleRecaptcha = value => {
    this.setState({ 'g-recaptcha-response': value })
  }

  handleSubmit = event => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(this.handleSuccess)
      .catch(error => alert(error))
    event.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      name: '',
      email: '',
      message: '',
      showModal: true,
    })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <Form
        name="contact"
        onSubmit={this.handleSubmit}
        data-netlify-recaptcha="true"
        data-netlify="true"
        data-netlify-honeypot="bot"
        overlay={this.state.showModal}
        onClick={this.closeModal}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Robocop Name: <input name="bot" onChange={this.handleInputChange} />
          </label>
        </p>

        <TextInput
          name="name"
          id="name"
          type="text"
          label="Full Name"
          placeholder="Enter your full name"
          value={this.state.name}
          onChange={this.handleInputChange}
          required
        />
        <TextInput
          name="email"
          id="email"
          type="email"
          label="Email"
          placeholder="Enter your email"
          value={this.state.email}
          onChange={this.handleInputChange}
          required
        />
        <TextArea
          name="message"
          id="message"
          type="text"
          label="Message"
          placeholder="How can we help? If this is a rate quote, provide details such as location, stops, palette requirements, etc."
          style={{ minHeight: '250px', resize: 'vertical' }}
          value={this.state.message}
          onChange={this.handleInputChange}
          required
        />
        <Recaptcha
          ref="recaptcha"
          sitekey={RECAPTCHA_KEY}
          onChange={this.handleRecaptcha}
        />

        <FormButton name="submit" type="submit" value="Send">
          Submit
        </FormButton>

        <Modal visible={this.state.showModal}>
          <p>
            Thank you for reaching out. We will get back to you as soon as
            possible.
          </p>
          <FormButton onClick={this.closeModal}>Okay</FormButton>
        </Modal>
      </Form>
    )
  }
}

ContactForm.propTypes = {
  data: PropTypes.object,
}

export default ContactForm

const Form = styled.form`
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  .input-group {
    width: 100%;
    margin: 0 0 1rem 0;
  }
  input,
  textarea {
    font-family: inherit;
    font-size: inherit;
    outline: none;
    background: white;
    color: ${darkGrey};
    border: 1px solid ${ltGrey};
    margin-bottom: 0.5em;
    padding: 1em;
    line-height: 1.6;
    width: 100%;
    &::-webkit-input-placeholder {
      color: ${mdGrey};
    }
    &::-moz-placeholder {
      color: ${mdGrey};
    }
    &:-ms-input-placeholder {
      color: ${mdGrey};
    }
    &:-moz-placeholder {
      color: ${mdGrey};
    }
    &:required {
      box-shadow: none;
    }
    &:focus {
      border-color: ${secondary};
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 3px rgba(0, 126, 255, 0.1);
      outline: none;
    }
  }
  input.error {
    border-color: ${danger};
  }
  .error .text-input {
    border-color: ${danger};
  }
  .input-feedback {
    color: ${danger};
    margin-top: 0.15rem;
    margin-bottom: 0.5rem;
    width: 100%;
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .error label {
    color: ${danger};
  }
  &::before {
    content: '';
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${props => (props.overlay ? '.8' : '0')};
    visibility: ${props => (props.overlay ? 'visible' : 'hidden')};
  }
  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  @keyframes shake {
    from,
    to {
      transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
      transform: translate3d(10px, 0, 0);
    }
  }
  .shake {
    animation-name: shake;
  }
`

const Submit = styled.input`
  background: ${props => props.theme.colors.base} !important;
  color: white !important;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: ${props => props.theme.colors.highlight} !important;
  }
`

const Modal = styled.div`
  background: white;
  padding: 2em;
  border-radius: 2px;
  position: fixed;
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    min-width: inherit;
    max-width: 400px;
  }
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }
`
